.btn-grid {
    &.row {
        margin: 0 -5px;

        >* {
            padding: 0 5px;
        }
    }

    .spinner-grow {
        display: block;
    }
}

.on-loading-btn {
    pointer-events: none;
}

.btn-color-primary {
    background-color: #30A7EA !important;
    border-color: #30A7EA !important;

}

.btn-color-secondary {
    background-color: #C5C5C5 !important;
    border-color: #C5C5C5 !important;

    .btn-label {
        color: #666666 !important;
    }

    &:active {
        background-color: #C5C5C5 !important;
        border-color: #C5C5C5 !important;
    }
}