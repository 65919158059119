/**======================================================================
=========================================================================
Template Name: Datta Able Free Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
@import "~bootstrap/dist/css/bootstrap";
// General theme contents
@import "partials/variables";
@import "partials/general";
@import "partials/generic";
@import "partials/mixins/function";

// important Element
@import "partials/menu/menu-lite";
@import "partials/widget/widget";

// Theme Element
@import "partials/theme-elements/form";
@import "partials/theme-elements/radiobox-checkbox";
@import "partials/theme-elements/labels-badges";
@import "partials/theme-elements/data-tables";
@import "partials/theme-elements/authentication";
@import "partials/theme-elements/button";
@import "partials/theme-elements/alert";
@import "partials/theme-elements/breadcrumb-pagination";
@import "partials/theme-elements/progress";
@import "partials/theme-elements/tooltip";
@import "partials/theme-elements/popover";
@import "partials/theme-elements/nav";
@import "partials/theme-elements/modal";

// Other
@import "partials/other/chat";
@import "partials/other/prism";
@import "partials/other/switches";

@import "partials/other/chart";
@import "partials/other/icon-lauouts";

@import "partials/third-party/third-party";


.custome-input {
  display: block;
  background: #fff;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: #04a9f5;
  border-color: #04a9f5;
  color: #fff;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.avatar {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.datepicks{
  font-size: 14px;
  border: 1px solid #999;
  text-align: center;
  color: #6174DD;
  font-weight: 600;
}
.datepicks-default{
  font-size: 12px;
  border: 1px solid #ced4da;
  background: #fff;
  color: #495057;
  font-weight: 400;
  width: 100%;
  height: auto;
  line-height: 1.5;
  padding: 10px 20px;
  font-size: 14px;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.warning {
  color: #ffc107
}

.info {
  color: #0dcaf0
}

.success {
  color: #198754
}

.danger {
  color: #dc3545
}

.container-desc {
  height: 50px;
}

.clamp-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
